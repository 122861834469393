import AddPackageIcon from '../../icons/AddPackage.Icon'
import AdminPanelSettingsIcon from '../../icons/AdminPanelSettings.Icon'
import AppleIcon from '../../icons/Apple.Icon'
import ArrowIcon from '../../icons/Arrow.icon'
import ArrowsIcon from '../../icons/Arrows'
import BellIcon from '../../icons/Bell.icon'
import CalendarIcon from '../../icons/Calendar.icon'
import CamsolIcon from '../../icons/Camsol.icon'
import CancelIcon from '../../icons/Cancel.icon'
import CaretIcon from '../../icons/Caret.icon'
import CheckIcon from '../../icons/Check.icon'
import NewCheckIcon from '../../icons/NewCheck.icon'
import CheckboxIcon from '../../icons/Checkbox.icon'
import CheckboxCheckedIcon from '../../icons/CheckboxChecked.icon'
import ChevronIcon from '../../icons/Chevron.icon'
import DropdownIcon from '../../icons/Dropdown.Icon'
import EyeIcon from '../../icons/Eye.icon'
import FacebookIcon from '../../icons/Facebook.Icon'
import GoogleIcon from '../../icons/Google.Icon'
import HelpIcon from '../../icons/Help.icon'
import HistoryIcon from '../../icons/History.icon'
import KeyIcon from '../../icons/Key.icon'
import LockIcon from '../../icons/Lock.icon'
import LogoutIcon from '../../icons/Logout.icon'
import MailIcon from '../../icons/Mail.icon'
import MailNotificationIcon from '../../icons/MailNotification.icon'
import MenuGridIcon from '../../icons/MenuGrid.icon'
import NormalEyeIcon from '../../icons/NormalEye.icon'
import NotificationIcon from '../../icons/Notification.icon'
import RightIcon from '../../icons/Right.Icon'
import SettingsIcon from '../../icons/Settings.icon'
import TeamIcon from '../../icons/Team.Icon'
import TransacIcon from '../../icons/Transac.Icon'
import UilIcon from '../../icons/Uil.icon'
import OverviewIcon from '../../icons/ViewOver.icon'
// import SearchIcon from '../../icons/Search.icon'
import ActivepackageIcon from '../../icons/ActivePackage.icon'
import AddIcon from '../../icons/Add.icon'
import AdminHistoryIcon from '../../icons/AdminHistory.icon'
import AdminTransactionIcon from '../../icons/AdminTransaction.icon'
import AdsClickIcon from '../../icons/AdsClick.icon'
import AnalyticsIcon from '../../icons/Analytics.icon'
import ArrowRightIcon from '../../icons/ArrowRight.icon'
import BarGraphOutlineIcon from '../../icons/BarGraphOutline.icon'
import BuildingIcon from '../../icons/Building.icon'
import BusinessIcon from '../../icons/Business.icon'
import CarDeliveryIcon from '../../icons/CarDelivery.icon'
import CashIcon from '../../icons/Cash.icon'
import CheckedBoxIcon from '../../icons/CheckedBox.icon'
import CheckmarkIcon from '../../icons/Checkmark.icon'
import ChevronRight from '../../icons/ChevronRight'
import ClockIcon from '../../icons/Clock.icon'
import CloseIcon from '../../icons/Close.icon'
import ControlButtonIcon from '../../icons/ControlButton.icon'
import CreditCardIcon from '../../icons/CreditCard.icon'
import CurrencyExchangeIcon from '../../icons/CurrencyExchange.Icon'
import DangerIcon from '../../icons/Danger.icon'
import DeactivatePersonIcon from '../../icons/DeactivatePerson.icon'
import DecreaseIcon from '../../icons/Decrease.icon'
import DonotDisturbOnIcon from '../../icons/DonotDisturbOn.icon'
import DotsIcon from '../../icons/Dots.icon'
import DownDropdownArrowIcon from '../../icons/DownDropdownArrow.icon'
import DownloadIcon from '../../icons/Download.icon'
import DownloadAltIcon from '../../icons/DownloadAlt.Icon'
import DownwardArrowIcon from '../../icons/DownwardArrow.icon'
import DownwardDecreasingIcon from '../../icons/DownwardDecreasing.icon'
import EditIcon from '../../icons/Edit.icon'
import ExpensesIcon from '../../icons/Expenses.icon'
import EyeOffIcon from '../../icons/EyeOff.icon'
import FilterIcon from '../../icons/Filter.icon'
import GalleryAddIcon from '../../icons/GalleryAdd.Icon'
import GreenOptionsIcon from '../../icons/GreenOptions'
import GreenTickIcon from '../../icons/GreenTick.Icon'
import GreenTickRoundIcon from '../../icons/GreenTickRound.icon'
import IncreaseIcon from '../../icons/Increase.icon'
import LeftArrowIcon from '../../icons/LeftArrowIcon'
import LeftPaginationIcon from '../../icons/LeftPagination.icon'
import LocationIcon from '../../icons/Location.icon'
import MinusIcon from '../../icons/Minus.icon'
import MultipleUsersIcon from '../../icons/MultipleUsers.icon'
import NeedleIcon from '../../icons/Needle.icon'
import OptionsIcon from '../../icons/Options.icon'
import PackageIcon from '../../icons/Package.icon'
import PaddingIcon from '../../icons/Padding.Icon'
import PdfIcon from '../../icons/Pdf.icon'
import PenIcon from '../../icons/Pen.icon'
import PencilIcon from '../../icons/Pencil.Icon'
import PendingIcon from '../../icons/Pending.icon'
import PendingActionIcon from '../../icons/PendingAction.icon'
import PersonIcon from '../../icons/Person.icon'
import ProfileIcon from '../../icons/Profile.icon'
import RadioButtonIcon from '../../icons/RadioButton.Icon'
import RejectedIcon from '../../icons/Rejected.icon'
import RightArrowIcon from '../../icons/RightArrowIcon'
import RightPaginationIcon from '../../icons/RightPagination.icon'
import SearchIcon from '../../icons/Search.icon'
import SquareCheckboxIcon from '../../icons/SquareCheckbox.icon'
import SquarePlusIcon from '../../icons/SquarePlus.icon'
import StackedLinesIcon from '../../icons/StackedLines.icon'
import StopIcon from '../../icons/Stop.icon'
import SuperCreditCard from '../../icons/SuperCreditCard.icon'
import SuspendIcon from '../../icons/Suspend.icon'
import SuspendModalIcon from '../../icons/SuspendModal.icon'
import SuspendTIcon from '../../icons/SuspendT.icon'
import TargetIcon from '../../icons/Target.icon'
import TickIcon from '../../icons/Tick.icon'
import TickMarkIcon from '../../icons/TickMark.icon'
import TransactionIcon from '../../icons/Transaction.icon'
import TrashIcon from '../../icons/Trash.icon'
import UnderlineCheckmarkIcon from '../../icons/UnderlineCheckmark.icon'
import UpArrowIcon from '../../icons/UpArrow.icon'
import UploadIcon from '../../icons/Upload.icon'
import UpwardArrowIcon from '../../icons/UpwardArrowIcon'
import UpwardIncreasingIcon from '../../icons/UpwardIncreasing.icon'
import UsersIcon from '../../icons/Users.icon'
import UsersOutlineIcon from '../../icons/UsersOutline.icon'
import ViewIcon from '../../icons/View.icon'
import WalletIcon from '../../icons/Wallet.icon'
import WarningIcon from '../../icons/Warning.icon'
import HardwareCircuitIcon from '../../icons/hardwareCircuit.icon'
import SuccessIcon from '../../icons/success.icon'

/**
 * Icons repository
 */

export const IconsSetArray = [
  { icon: <PendingActionIcon /> },
  { icon: <PendingIcon /> },
  {
    icon: <GreenTickRoundIcon />,
  },
  {
    icon: <SuspendModalIcon />,
  },
  {
    icon: <SuspendTIcon />,
  },
  {
    icon: <RejectedIcon />,
  },
  {
    icon: <AppleIcon />,
  },
  {
    icon: <FacebookIcon />,
  },
  {
    icon: <CancelIcon />,
  },
  {
    icon: <CarDeliveryIcon />,
  },
  {
    icon: <ClockIcon />,
  },
  {
    icon: <FilterIcon />,
  },
  {
    icon: <LeftPaginationIcon />,
  },
  {
    icon: <RightPaginationIcon />,
  },
  {
    icon: <OptionsIcon />,
  },
  {
    icon: <StopIcon />,
  },
  {
    icon: <TickIcon />,
  },
  {
    icon: <WarningIcon />,
  },
  {
    icon: <SquareCheckboxIcon />,
  },
  {
    icon: <LocationIcon />,
  },
  {
    icon: <CashIcon />,
  },
  {
    icon: <CreditCardIcon />,
  },
  {
    icon: <UploadIcon />,
  },
  {
    icon: <UnderlineCheckmarkIcon />,
  },
  {
    icon: <TrashIcon />,
  },
  {
    icon: <PdfIcon />,
  },
  {
    icon: <AddIcon />,
  },
  {
    icon: <BuildingIcon />,
  },
  {
    icon: <SquarePlusIcon />,
  },
  {
    icon: <WarningIcon />,
  },
  {
    icon: <PencilIcon />,
  },
  {
    icon: <SuspendIcon />,
  },
  {
    icon: <DeactivatePersonIcon />,
  },
  {
    icon: <ViewIcon />,
  },
  {
    icon: <MultipleUsersIcon />,
  },
  {
    icon: <DownwardDecreasingIcon />,
  },
  {
    icon: <UpwardIncreasingIcon />,
  },
  {
    icon: <SuperCreditCard />,
  },
  {
    icon: <ControlButtonIcon />,
  },
  {
    icon: <DownDropdownArrowIcon />,
  },
  {
    icon: <TickMarkIcon />,
  },
  {
    icon: <CheckedBoxIcon />,
  },
  {
    icon: <MinusIcon />,
  },
  {
    icon: <PenIcon />,
  },

  {
    icon: <DotsIcon />,
  },
  {
    icon: <EyeOffIcon />,
  },
  {
    icon: <PersonIcon />,
  },
]

export const IconRepository = {
  PendingActionIcon,
  PendingIcon,
  GreenTickRoundIcon,
  SuspendModalIcon,
  TickMarkIcon,
  SuspendTIcon,
  RejectedIcon,
  AddPackageIcon,
  AdminPanelSettingsIcon,
  CalendarIcon,
  ArrowsIcon,
  AppleIcon,
  FacebookIcon,
  GoogleIcon,
  EyeIcon,
  CancelIcon,
  NormalEyeIcon,
  LockIcon,
  CheckboxIcon,
  CheckboxCheckedIcon,
  CaretIcon,
  DropdownIcon,
  CamsolIcon,
  BellIcon,
  HistoryIcon,
  GreenTickIcon,
  MenuGridIcon,
  UilIcon,
  LogoutIcon,
  SettingsIcon,
  RightIcon,
  CheckIcon,
  NewCheckIcon,
  MailNotificationIcon,
  KeyIcon,
  MailIcon,
  ArrowIcon,
  HelpIcon,
  ChevronIcon,
  NotificationIcon,
  // SearchIcon,
  GreenOptionsIcon,
  UpwardArrowIcon,
  RightArrowIcon,
  LeftArrowIcon,
  TrashIcon,
  EditIcon,
  CloseIcon,
  CheckmarkIcon,
  ChevronRight,
  GalleryAddIcon,
  RadioButtonIcon,
  DownloadIcon,
  UpArrowIcon,
  HardwareCircuitIcon,
  StackedLinesIcon,
  BarGraphOutlineIcon,
  BusinessIcon,
  UsersOutlineIcon,
  TransactionIcon,
  NeedleIcon,
  OverviewIcon,
  UsersIcon,
  AdminTransactionIcon,
  ExpensesIcon,
  WalletIcon,
  AdminHistoryIcon,
  TargetIcon,
  ProfileIcon,
  AnalyticsIcon,
  PackageIcon,
  DonotDisturbOnIcon,
  ActivepackageIcon,
  DownwardArrowIcon,
  IncreaseIcon,
  DecreaseIcon,
  ArrowRightIcon,
  CarDeliveryIcon,
  ClockIcon,
  FilterIcon,
  LeftPaginationIcon,
  RightPaginationIcon,
  OptionsIcon,
  TransacIcon,
  StopIcon,
  TickIcon,
  WarningIcon,
  SquareCheckboxIcon,
  SearchIcon,
  AdsClickIcon,
  TeamIcon,
  AddIcon,
  LocationIcon,
  CreditCardIcon,
  CashIcon,
  UploadIcon,
  UnderlineCheckmarkIcon,
  PdfIcon,
  DangerIcon,
  BuildingIcon,
  SquarePlusIcon,
  ViewIcon,
  DeactivatePersonIcon,
  SuspendIcon,
  PaddingIcon,
  CurrencyExchangeIcon,
  DownloadAltIcon,
  PencilIcon,
  MultipleUsersIcon,
  DownwardDecreasingIcon,
  UpwardIncreasingIcon,
  SuperCreditCard,
  ControlButtonIcon,
  DownDropdownArrowIcon,
  CheckedBoxIcon,
  MinusIcon,
  PenIcon,
  DotsIcon,
  EyeOffIcon,
  PersonIcon,
  SuccessIcon,
}
