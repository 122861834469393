import React from 'react'

// general imports
import { IconPropsType } from '../types'

const NewCheckIcon = ({ width = 48, height = 48, color = 'currentColor' }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 106 106'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='53' cy='53' r='53' fill='#27B524' fillOpacity='0.1' />
      <circle cx='53' cy='53' r='34.7812' fill='#029E31' />
      <path
        d='M64.0423 44.7188L48.86 59.901L41.959 53'
        stroke={color}
        strokeWidth='5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default NewCheckIcon
